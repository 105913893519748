<div style="height: 110px; margin: 10px;">
	<!-- Search bar -->
	<uno-searchbar [value]="selfStatic.filters.search" (valueChange)="onSearch($event)"></uno-searchbar>

	<uno-filter-bar [filters]="selfStatic.filters" [options]="selfStatic.filterOptions" (onChange)="this.onFilterChange($event);"></uno-filter-bar>
</div>

<!-- List -->
<uno-content [ngStyle]="{height: 'calc(100% - 130px)'}">
	@if (this.items.length === 0) {
		<uno-no-data></uno-no-data>
	}
	@if (this.items.length !== 0) {
		@for (item of this.items; track item) {
			<asset-tree-list-item [item]="item" [search]="selfStatic.filters.search" [searchFields]="selfStatic.filters.searchFields" [sortDirection]="selfStatic.filters.sortDirection" [sortField]="selfStatic.filters.sortField"></asset-tree-list-item>
		}
	}
</uno-content>
